import React, { useEffect, useState } from "react";
import "./categories.css";
import background_img from "../../../../img/background_img.webp";
import { toast } from "react-toastify";

const Categories = () => {
  const [Categories, setCategories] = useState(null);
  const [image, setImage] = useState(null);
  const [textENCategories, settextENCategories] = useState("");
  const [textARCategories, settextARCategories] = useState("");
  const [allDataCategories, setAllDataCategories] = useState([]);
  const [isDisabledCategories, setDisabledCategories] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchCategories = async () => {
    const formDataViewCategories = new FormData();
    formDataViewCategories.append("validation", validation);

    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_categories.php`,
        {
          method: "POST",
          body: formDataViewCategories,
        }
      );
      const dataCategories = await response.json();
      setAllDataCategories(dataCategories);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoriesChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setCategories(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleTextChangeENCategories = (e) => {
    settextENCategories(e.target.value);
  };

  const handleTextChangeARCategories = (e) => {
    settextARCategories(e.target.value);
  };

  const handleSubmitCategories = async (e) => {
    e.preventDefault();

    setDisabledCategories(true);

    const formDataCategories = new FormData();
    formDataCategories.append("validation", validation);
    formDataCategories.append("image_categories", image);
    formDataCategories.append("lang_categories_en", textENCategories);
    formDataCategories.append("lang_categories_ar", textARCategories);

    const response = await fetch(`${LinkLikeBachend}create/categories.php`, {
      method: "POST",
      body: formDataCategories,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("Data added successfully");

        setImage(null);
        setCategories(null);
        settextENCategories("");
        settextARCategories("");
        document.getElementById("file_Categories").value = "";

        fetchCategories();

        setDisabledCategories(false);
      } else {
        toast.error("Error adding data");
        console.log(resultText);
        setDisabledCategories(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error adding data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledCategories(false);
    }
  };

  const handleDeleteClick = async (categoryId) => {
    const formDataCategories = new FormData();
    formDataCategories.append("validation", validation);
    formDataCategories.append("categoryId", categoryId);

    const response = await fetch(`${LinkLikeBachend}delete/categories.php`, {
      method: "POST",
      body: formDataCategories,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been deleted successfully");

        fetchCategories();
      } else {
        toast.error("Error deleting data");
        console.log(resultText);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error deleting data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
    }
  };

  return (
    <div className="categories">
      <div className="upload_form">
        <form encType="multipart/form-data" onSubmit={handleSubmitCategories}>
          <div className="images">
            {Categories ? (
              <img src={Categories} alt="selected_img" />
            ) : (
              <img src={background_img} alt="background_img" />
            )}

            <input
              type="file"
              id="file_Categories"
              name="image_Categories"
              accept="image/*"
              onChange={handleCategoriesChange}
              required
            />
          </div>

          <input
            type="text"
            name="text_title_en"
            required
            placeholder="text title en"
            value={textENCategories}
            onChange={handleTextChangeENCategories}
          />

          <input
            style={{ direction: "rtl" }}
            type="text"
            name="text_title_ar"
            required
            placeholder="text title ar"
            value={textARCategories}
            onChange={handleTextChangeARCategories}
          />

          <input
            type="submit"
            value="Submit"
            style={{ opacity: isDisabledCategories ? 0.3 : 1 }}
            disabled={isDisabledCategories}
          />
        </form>

        <div className="view_category">
          {allDataCategories.length > 0 ? (
            allDataCategories.map((category, index) => (
              <div className="div_category" key={index}>
                <span
                  className="delete_category"
                  onClick={() => handleDeleteClick(category.id_categories)}
                >
                  ×
                </span>
                <img
                  src={`${LinkLikeBachend}${category.image_categories}`}
                  alt="pastel_sketch"
                />
                <p>{category.lang_categories_en}</p>
                <p>{category.lang_categories_ar}</p>
              </div>
            ))
          ) : (
            <p>لا توجد تصنيفات</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Categories;
