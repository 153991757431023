import React, { useEffect } from "react";
import "./headerAdmin.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const HeaderAdmin = () => {
  const [cookiesValid, setCookiesValid, removeCookie] = useCookies([
    "validation_login",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (cookiesValid.validation_login !== "valid") {
      navigate("/admin", { replace: true });
    }
  }, []);

  const handleLogOut = () => {
    removeCookie("validation_login");
    navigate("/admin", { replace: true });
  };

  return (
    <>
      <div className="header_admin">
        <ul>
          <li>
            <NavLink to="/dashboard">Banner</NavLink>
          </li>

          <li>
            <NavLink to="categories">Categories</NavLink>
          </li>

          <li>
            <NavLink to="all-post">All Post</NavLink>
          </li>

          <li>
            <NavLink to="add-about">About</NavLink>
          </li>

          <li>
            <NavLink to="contact">Contact</NavLink>
          </li>

          <li>
            <NavLink to="featured-collections">Featured Collections</NavLink>
          </li>

          <button onClick={handleLogOut}>LogOut</button>
        </ul>
      </div>

      <Outlet />
    </>
  );
};

export default HeaderAdmin;
