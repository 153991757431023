import React, { useEffect, useState } from "react";
import "./addPost.css";
import background_img from "../../../../img/background_img.webp";
import { toast } from "react-toastify";

const AddPost = () => {
  const [imagePost, setImagePost] = useState(null);
  const [isDisabledAddPost, setDisabledAddPost] = useState(false);
  const [allDataCategories, setAllDataCategories] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [formData, setFormData] = useState({
    validation: validation,
    image_post: null,
    title_post_en: "",
    title_post_ar: "",
    description_post_en: "",
    description_post_ar: "",
    categories_post: "",
  });

  const fetchCategories = async () => {
    const formDataViewCategories = new FormData();
    formDataViewCategories.append("validation", validation);

    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_categories.php`,
        {
          method: "POST",
          body: formDataViewCategories,
        }
      );
      const dataCategories = await response.json();
      setAllDataCategories(dataCategories);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleImageChangePost = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePost(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitAddPost = async (event) => {
    event.preventDefault();

    setDisabledAddPost(true);

    const formDataPost = new FormData();

    for (const key in formData) {
      formDataPost.append(key, formData[key]);
    }

    const response = await fetch(`${LinkLikeBachend}create/post.php`, {
      method: "POST",
      body: formDataPost,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("Data added successfully");

        setImagePost(null);
        setFormData({
          validation: validation,
          image_post: null,
          title_post_en: "",
          title_post_ar: "",
          description_post_en: "",
          description_post_ar: "",
          categories_post: "",
        });

        document.getElementById("file_post").value = "";

        setDisabledAddPost(false);
      } else {
        toast.error("Error adding data");
        console.log(resultText);
        setDisabledAddPost(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error adding data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledAddPost(false);
    }
  };

  const handleChangePost = (event) => {
    const { name, files, value } = event.target;
    setFormData((prevFormData) => {
      const newData = { ...prevFormData };
      newData[name] = files ? files[0] : value;
      return newData;
    });
  };

  const handleCombinedChange = (event) => {
    const { name } = event.target;

    if (name === "image_post") {
      handleImageChangePost(event);
      handleChangePost(event);
    } else {
      handleChangePost(event);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="add_post">
      <div className="upload_form">
        <form encType="multipart/form-data" onSubmit={handleSubmitAddPost}>
          <div className="images">
            {imagePost ? (
              <img src={imagePost} alt="selected_img" />
            ) : (
              <img src={background_img} alt="background_img" />
            )}

            <input
              type="file"
              id="file_post"
              name="image_post"
              accept="image/*"
              required
              onChange={handleCombinedChange}
            />
          </div>

          <div className="div_input">
            <input
              type="text"
              name="title_post_en"
              required
              placeholder="title post en"
              onChange={handleChangePost}
              value={formData.title_post_en}
            />
            <input
              style={{ direction: "rtl" }}
              type="text"
              name="title_post_ar"
              required
              placeholder="عنوان المنشور عربي"
              onChange={handleChangePost}
              value={formData.title_post_ar}
            />
          </div>

          <div className="div_input">
            <input
              type="text"
              name="description_post_en"
              required
              placeholder="description post en"
              onChange={handleChangePost}
              value={formData.description_post_en}
            />

            <input
              style={{ direction: "rtl" }}
              type="text"
              name="description_post_ar"
              required
              placeholder="وصف المنشور عربي"
              onChange={handleChangePost}
              value={formData.description_post_ar}
            />
          </div>

          <div className="div_input">
            <select
              name="categories_post"
              id="categories_post"
              required
              onChange={handleChangePost}
              value={formData.categories_post}
            >
              <option value="">select categories</option>

              {allDataCategories.length > 0 ? (
                allDataCategories.map((category, index) => (
                  <React.StrictMode key={index}>
                    <option value={category.id_categories}>
                      {category.lang_categories_en}
                    </option>
                  </React.StrictMode>
                ))
              ) : (
                <></>
              )}
            </select>
          </div>

          <input
            type="submit"
            value="Submit"
            style={{ opacity: isDisabledAddPost ? 0.3 : 1 }}
            disabled={isDisabledAddPost}
          />
        </form>
      </div>
    </div>
  );
};

export default AddPost;
