export const arabicDataAr = {
  home: "الرئيسية",
  categories: "التصنيفات",
  about_us: "معلومات عنا",
  contact_us: "اتصل بنا",
  featured_collections: "مجموعات مميزة",
  first_name: "الاسم الأول",
  last_name: "اسم العائلة",
  email: "بريد إلكتروني",
  phone: "رقم التليفون",
  company_name: "اسم الشركة",
  country: "دولة",
  select_country: "اختر دولة",
  submit: "ارسال",
  approval_news_offers:
    "نعم | ارغب في تلقي الأخبار والعروض عبر البريد الإلكتروني",
  consent_receive_phone_calls: "نعم | اوافق على تلقي المكالمات الهاتفية",
  all_Categories: "جميع التصنيفات",
  no_items: "لا توجد عناصر لعرضها",
  sent_successfully: "تم ارسال الرسالة بنجاح",
  sent_error: "خطأ عند ارسال الرسالة",
};

export default arabicDataAr;
