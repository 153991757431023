import React, { useEffect, useState } from "react";
import "./gallery.css";
import { NavLink } from "react-router-dom";

import ScrollVisibility from "../../../components/ScrollVisibility/ScrollVisibility";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../components/context/AppProvider";

const Gallery = () => {
  const { t } = useTranslation();
  const [allDataCategories, setAllDataCategories] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const { direction } = useAppContext();

  const fetchCategories = async () => {
    const formDataViewCategories = new FormData();
    formDataViewCategories.append("validation", validation);

    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_categories.php`,
        {
          method: "POST",
          body: formDataViewCategories,
        }
      );
      const dataCategories = await response.json();
      setAllDataCategories(dataCategories);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const randomIndex = Math.floor(Math.random() * allDataCategories.length);

  return (
    <div className="gallery">
      <h2>{t("categories")}</h2>

      <div className="parent-container">
        {allDataCategories.length > 0 ? (
          <ScrollVisibility>
            <NavLink className="div_category" to="/post">
              <img
                src={
                  allDataCategories.length > 0
                    ? `${LinkLikeBachend}${allDataCategories[randomIndex].image_categories}`
                    : ""
                }
                alt="realistic"
              />
              <p>{t("all_Categories")}</p>
            </NavLink>
          </ScrollVisibility>
        ) : (
          <></>
        )}

        {allDataCategories.length > 0 ? (
          allDataCategories.map((category, index) =>
            direction === "ltr" ? (
              <ScrollVisibility key={index}>
                <NavLink
                  className="div_category"
                  to={`/post/${category.id_categories}`}
                >
                  <img
                    src={`${LinkLikeBachend}${category.image_categories}`}
                    alt="pastel_sketch"
                  />
                  <p>{category.lang_categories_en}</p>
                </NavLink>
              </ScrollVisibility>
            ) : (
              <ScrollVisibility key={index}>
                <NavLink
                  className="div_category"
                  to={`/post/${category.id_categories}`}
                >
                  <img
                    src={`${LinkLikeBachend}${category.image_categories}`}
                    alt="pastel_sketch"
                  />
                  <p>{category.lang_categories_ar}</p>
                </NavLink>
              </ScrollVisibility>
            )
          )
        ) : (
          <p>لا توجد تصنيفات</p>
        )}
      </div>
    </div>
  );
};

export default Gallery;
