export const englishDataEn = {
  home: "Home",
  categories: "Categories",
  about_us: "About us",
  contact_us: "Contact Us",
  featured_collections: "Featured Collections",
  first_name: "First Name",
  last_name: "Last Name",
  email: "E-mail",
  phone: "Phone Number",
  company_name: "Company Name",
  country: "Country",
  select_country: "Select a Country",
  submit: "Submit",
  approval_news_offers:
    "Yes, | would like to receive news and offers via email",
  consent_receive_phone_calls: "Yes, | agree to receive phone calls",
  all_Categories: "All Categories",
  no_items: "There are no items to display",
  sent_successfully: "The message was sent successfully",
  sent_error: "Error when sending the message",
};

export default englishDataEn;
