import React, { useEffect, useState } from "react";
import "./addFeaturedCollections.css";
import { toast } from "react-toastify";
import background_img from "../../../../img/background_img.webp";

const AddFeaturedCollections = () => {
  const [selectedImageSlider, setSelectedImageSlider] = useState(null);
  const [imagePreviewSlider, setImagePreviewSlider] = useState(null);
  const [isButtonDisabledSlider, setButtonDisabledSlider] = useState(false);
  const [imagesSliders, setImagesSliders] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchImages = async () => {
    const formDataViewSlider = new FormData();

    formDataViewSlider.append("validation", validation);

    try {
      const response = await fetch(`${LinkLikeBachend}read/view_slider.php`, {
        method: "POST",
        body: formDataViewSlider,
      });
      const dataSliders = await response.json();
      setImagesSliders(dataSliders);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageChangeSlider = (e) => {
    const imageSlider = e.target.files[0];
    setSelectedImageSlider(imageSlider);

    if (imageSlider) {
      const imageUrlSlider = URL.createObjectURL(imageSlider);
      setImagePreviewSlider(imageUrlSlider);
    }
  };

  const handleUploadSlider = async (e) => {
    e.preventDefault();

    setButtonDisabledSlider(true);

    const formDataSlider = new FormData();
    formDataSlider.append("image_featured_collections", selectedImageSlider);
    formDataSlider.append("validation", validation);

    const response = await fetch(`${LinkLikeBachend}create/add_Slider.php`, {
      method: "POST",
      body: formDataSlider,
    });

    const result = await response.json();

    if (response.ok) {
      if (result.message == "successfully") {
        toast.success("The image has been uploaded successfully");

        setSelectedImageSlider("");
        setImagePreviewSlider("");

        let inputFile = document.querySelector(".inputFile");

        inputFile.value = "";

        setButtonDisabledSlider(false);

        fetchImages();
      } else {
        toast.error("An error occurred while adding the image");
        console.log(result.message);
        console.log(result.error);

        setButtonDisabledSlider(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setButtonDisabledSlider(false);
    }
  };

  const handleRemoveImage = async (idSlider) => {
    const formDataRemoveImage = new FormData();
    formDataRemoveImage.append("idSlider", idSlider);
    formDataRemoveImage.append("validation", validation);

    const response = await fetch(`${LinkLikeBachend}delete/slider.php`, {
      method: "POST",
      body: formDataRemoveImage,
    });

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The image has been uploaded successfully");

        fetchImages();

        // setImagesSliders(
        //   imagesSliders.filter((image) => image.id !== idSlider)
        // );
      } else {
        toast.error("An error occurred while adding the image");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  return (
    <>
      <form
        method="POST"
        onSubmit={handleUploadSlider}
        className="formUploadLogo"
      >
        <h2>Slider</h2>

        <div className="fileUploadLogo">
          <input
            type="file"
            onChange={handleImageChangeSlider}
            className="inputFile"
            accept="image/*"
            required
          />
          <img
            src={imagePreviewSlider ? imagePreviewSlider : background_img}
            alt="معاينة الصورة"
            id="logoInput"
            className="previewImage"
          />
        </div>

        <button type="submit" disabled={isButtonDisabledSlider}>
          Submit
        </button>
      </form>

      {imagesSliders.length > 0 ? (
        <div className="imagesSlider">
          {imagesSliders.map((image, index) => (
            <div key={index} className="imageSlider">
              <button onClick={() => handleRemoveImage(image.id)}>×</button>
              <img
                src={`${LinkLikeBachend}${image.image_featured_collections}`}
                alt={`Image_${index}`}
              />
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </>
  );
};

export default AddFeaturedCollections;
