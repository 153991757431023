import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "../addPost.css";

const AllPost = () => {
  const [allDataPost, setAllDataPost] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataPost = async () => {
    const formDataViewPost = new FormData();
    formDataViewPost.append("validation", validation);
    formDataViewPost.append("categoriesId", "undefined");

    try {
      const responsePost = await fetch(`${LinkLikeBachend}read/view_post.php`, {
        method: "POST",
        body: formDataViewPost,
      });
      const dataPost = await responsePost.json();
      setAllDataPost(dataPost);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataPostCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);
      formDataCount.append("categoriesId", "undefined");

      const responsePostCount = await fetch(
        `${LinkLikeBachend}read/view_post_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataPostCount = await responsePostCount.text();

      setPostCount(dataPostCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewPostPage = new FormData();
    formDataViewPostPage.append("validation", validation);
    formDataViewPostPage.append("categoriesId", "undefined");
    formDataViewPostPage.append("page", currentPage + 1);

    try {
      const responsePost = await fetch(`${LinkLikeBachend}read/view_post.php`, {
        method: "POST",
        body: formDataViewPostPage,
      });
      const dataPost = await responsePost.json();

      setTimeout(() => {
        setAllDataPost((prevData) => prevData.concat(dataPost));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleDeletePoste = async (idPost) => {
    setCurrentPage(1);
    try {
      const formDatadelete = new FormData();
      formDatadelete.append("validation", validation);
      formDatadelete.append("idPost", idPost);

      const responsePostDelete = await fetch(
        `${LinkLikeBachend}delete/post.php`,
        {
          method: "POST",
          body: formDatadelete,
        }
      );

      const dataPostDelete = await responsePostDelete.text();

      if (dataPostDelete == "successfully") {
        toast.success("The data has been deleted successfully");
        fetchDataPost();
        fetchDataPostCount();
      } else {
        toast.error("Error when data is deleted successfully");
        console.log(dataPostDelete);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      toast.error("Error when data is deleted successfully");
    }
  };

  useEffect(() => {
    fetchDataPost();
    fetchDataPostCount();
  }, []);

  return (
    <>
      <NavLink className="add-post" to="/dashboard/add-post">
        Add Post
      </NavLink>

      <div className="all_postes">
        {allDataPost && allDataPost.length > 0 ? (
          allDataPost.map((item) => (
            <div key={item.id_post} className="poste">
              <span
                className="delete_poste"
                onClick={() => {
                  handleDeletePoste(item.id_post);
                }}
              >
                ×
              </span>

              <img
                src={`${LinkLikeBachend}${item.image_post}`}
                alt={item.title_post_en}
              />
              <div>
                <p>title post en:</p>
                <p>{item.title_post_en}</p>
              </div>

              <div>
                <p>title post ar:</p>
                <p>{item.title_post_ar}</p>
              </div>

              <div>
                <p>description post en:</p>
                <p>{item.description_post_en}</p>
              </div>

              <div>
                <p>description post ar:</p>
                <p>{item.description_post_ar}</p>
              </div>
            </div>
          ))
        ) : (
          <p>There are no items to display</p>
        )}
      </div>

      <div className="div_load_more">
        {allDataPost &&
          allDataPost.length > 0 &&
          parseInt(postCount) !== allDataPost.length && (
            <button
              className="load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "Load More" : "Loading..."}
            </button>
          )}
      </div>
    </>
  );
};

export default AllPost;
