import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./post.css";
import { useAppContext } from "../../../components/context/AppProvider";
import { CiHeart } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircle } from "react-icons/io";

const Post = () => {
  const [allDataPost, setAllDataPost] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { direction } = useAppContext();
  const { categoriesId } = useParams();
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataPost = async () => {
    const formDataViewPost = new FormData();
    formDataViewPost.append("validation", validation);
    formDataViewPost.append("categoriesId", categoriesId);

    try {
      const responsePost = await fetch(`${LinkLikeBachend}read/view_post.php`, {
        method: "POST",
        body: formDataViewPost,
      });
      const dataPost = await responsePost.json();
      setAllDataPost(dataPost);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataPostCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);
      formDataCount.append("categoriesId", categoriesId);

      const responsePostCount = await fetch(
        `${LinkLikeBachend}read/view_post_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataPostCount = await responsePostCount.text();

      setPostCount(dataPostCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewPostPage = new FormData();
    formDataViewPostPage.append("validation", validation);
    formDataViewPostPage.append("page", currentPage + 1);
    formDataViewPostPage.append("categoriesId", categoriesId);

    try {
      const responsePost = await fetch(`${LinkLikeBachend}read/view_post.php`, {
        method: "POST",
        body: formDataViewPostPage,
      });
      const dataPost = await responsePost.json();

      setTimeout(() => {
        setAllDataPost((prevData) => prevData.concat(dataPost));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataPost();
    fetchDataPostCount();
  }, []);

  return (
    <>
      <div className="all_postes">
        {allDataPost && allDataPost.length > 0 ? (
          direction === "ltr" ? (
            allDataPost.map((item, index) => (
              <div
                key={index}
                className="poste"
                onClick={() =>
                  setSelectedImage(`${LinkLikeBachend}${item.image_post}`)
                }
              >
                <img
                  src={`${LinkLikeBachend}${item.image_post}`}
                  alt={item.title_post_en}
                />
                <CiHeart />
                <div>
                  <p>{item.title_post_en}</p>
                </div>

                <div>
                  <p>{item.description_post_en}</p>
                </div>
              </div>
            ))
          ) : (
            allDataPost.map((item, index) => (
              <div
                key={index}
                className="poste"
                onClick={() =>
                  setSelectedImage(`${LinkLikeBachend}${item.image_post}`)
                }
              >
                <img
                  src={`${LinkLikeBachend}${item.image_post}`}
                  alt={item.title_post_ar}
                />
                <CiHeart />
                <div>
                  <p>{item.title_post_ar}</p>
                </div>

                <div>
                  <p>{item.description_post_ar}</p>
                </div>
              </div>
            ))
          )
        ) : (
          <p>{t("no_items")}</p>
        )}
      </div>

      <div className="div_load_more">
        {allDataPost &&
          allDataPost.length > 0 &&
          parseInt(postCount) !== allDataPost.length && (
            <button
              className="load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "Load More" : "Loading..."}
            </button>
          )}
      </div>

      {selectedImage && (
        <div className="view_img_div">
          <img src={selectedImage} alt="Selected Image" />
          <span onClick={() => setSelectedImage(null)}>
            <IoIosCloseCircle />
          </span>
        </div>
      )}
    </>
  );
};

export default Post;
