import React, { useEffect, useState } from "react";
import "./about.css";
import ScrollVisibility from "../../../components/ScrollVisibility/ScrollVisibility";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../components/context/AppProvider";

const About = () => {
  const { t } = useTranslation();
  const [imagesAbout, setImagesAbout] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const { direction } = useAppContext();

  const fetchDataAbout = async () => {
    const formDataViewAbout = new FormData();
    formDataViewAbout.append("validation", validation);

    try {
      const response = await fetch(`${LinkLikeBachend}read/view_about.php`, {
        method: "POST",
        body: formDataViewAbout,
      });
      const dataAbout = await response.json();
      setImagesAbout(dataAbout);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataAbout();
  }, []);

  // console.log(imagesAbout);

  return (
    <section className="about-me" id="about-me">
      <h2 className="about-me-title">{t("about_us")}</h2>
      <ScrollVisibility>
        <div className="container">
          <div className="about-me-container">
            <div className="about-me-flex-container">
              <div className="about-me-image">
                <div className="main-image">
                  <img
                    src={
                      imagesAbout && imagesAbout.length > 0
                        ? `${LinkLikeBachend}${imagesAbout[0].img_about}`
                        : ""
                    }
                    alt="img_about"
                  />
                </div>
              </div>
              <div className="about-me-content">
                <div className="logo">
                  <img
                    src={
                      imagesAbout && imagesAbout.length > 0
                        ? `${LinkLikeBachend}${imagesAbout[0].img_logo}`
                        : ""
                    }
                    alt="logo"
                  />
                </div>

                {direction === "ltr" ? (
                  <span className="text">
                    {imagesAbout && imagesAbout.length > 0
                      ? `${imagesAbout[0].title_about_en}`
                      : ""}
                  </span>
                ) : (
                  <span className="text">
                    {imagesAbout && imagesAbout.length > 0
                      ? `${imagesAbout[0].title_about_ar}`
                      : ""}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </ScrollVisibility>
    </section>
  );
};

export default About;
