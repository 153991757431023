import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import "./header.css";
import Copyrights from "../copyrights/Copyrights";
import logo from "../../img/logo.webp";
import { useTranslation } from "react-i18next";

const Header = ({ setDirection, direction }) => {
  const [isChecked, setChecked] = useState(false);
  const location = useLocation();
  const { i18n, t } = useTranslation();

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  useEffect(() => {
    setChecked(false);
  }, [location.pathname]);

  return (
    <>
      <nav>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />
        <NavLink to="/" className="logo">
          <img src={logo} alt="logo" />
        </NavLink>

        {direction == "ltr" ? (
          <button className="ChangeLang" onClick={ChangeAR}>
            AR
          </button>
        ) : (
          <button className="ChangeLang" onClick={ChangeEN}>
            EN
          </button>
        )}

        <ul className="links">
          <li>
            <NavLink to="/">{t("home")}</NavLink>
          </li>
          <li>
            <NavLink to="/about">{t("about_us")}</NavLink>
          </li>
          <li>
            <NavLink to="/gallery">{t("categories")}</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">{t("contact_us")}</NavLink>
          </li>
        </ul>

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>

      <Outlet />

      <Copyrights />
    </>
  );
};

export default Header;
