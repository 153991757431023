import React from "react";
import { createGlobalStyle } from "styled-components";
import { useAppContext } from "../context/AppProvider";

const GlobalStyleComponent = createGlobalStyle`
  @font-face {
    font-family: 'Arial';
    src: local('Arial'), url(${(props) =>
      props.isLTR ? "./arial.woff" : "./ge-ss.woff"}) format('woff');
  }

  body {
    font-family: ${({ isLTR }) =>
      isLTR === "ltr" ? "'Arial', sans-serif" : "'Ge ss', sans-serif"};
  }
`;

const GlobalStyle = () => {
  const { direction } = useAppContext();

  return <GlobalStyleComponent isLTR={direction} />;
};

export default GlobalStyle;
