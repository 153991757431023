import React, { useEffect, useState } from "react";
import "./addAbout.css";
import background_img from "../../../../img/background_img.webp";
import { toast } from "react-toastify";

const AddAbout = () => {
  const [imageAbout, setImageAbout] = useState(null);
  const [image, setImage] = useState(null);
  const [imageLogoAbout, setImageLogoAbout] = useState(null);
  const [imageLogo, setImageLogo] = useState(null);
  const [textEN, setTextEN] = useState("");
  const [textAR, setTextAR] = useState("");
  const [isDisabledAddAbout, setDisabledAddAbout] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [imagesAbout, setImagesAbout] = useState([]);

  const fetchDataAbout = async () => {
    const formDataViewAbout = new FormData();
    formDataViewAbout.append("validation", validation);

    try {
      const response = await fetch(`${LinkLikeBachend}read/view_about.php`, {
        method: "POST",
        body: formDataViewAbout,
      });
      const dataAbout = await response.json();
      setImagesAbout(dataAbout);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataAbout();
  }, []);

  const handleImageChangeAbout = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageAbout(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChangeLogo = (e) => {
    const file = e.target.files[0];
    setImageLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageLogoAbout(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleTextChangeEN = (e) => {
    setTextEN(e.target.value);
  };

  const handleTextChangeAR = (e) => {
    setTextAR(e.target.value);
  };

  const handleSubmitAbout = async (e) => {
    e.preventDefault();

    setDisabledAddAbout(true);

    const formDataAddAbout = new FormData();
    formDataAddAbout.append("validation", validation);
    formDataAddAbout.append("img_logo", imageLogo);
    formDataAddAbout.append("img_about", image);
    formDataAddAbout.append(
      "title_about_en",
      textEN || (imagesAbout.length > 0 && imagesAbout[0].title_about_en)
    );
    formDataAddAbout.append(
      "title_about_ar",
      textAR || (imagesAbout.length > 0 && imagesAbout[0].title_about_ar)
    );

    const response = await fetch(`${LinkLikeBachend}update/about.php`, {
      method: "POST",
      body: formDataAddAbout,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been updated successfully");

        setImage(null);
        setImageLogo(null);
        setTextEN("");

        fetchDataAbout();

        setDisabledAddAbout(false);
      } else {
        toast.error("Error updating data");
        console.log(resultText);
        setDisabledAddAbout(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error updating data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledAddAbout(false);
    }
  };

  return (
    <div className="upload_form">
      <form encType="multipart/form-data" onSubmit={handleSubmitAbout}>
        <div className="images logo_about">
          {imageLogoAbout ? (
            <img src={imageLogoAbout} alt="selected_img" />
          ) : imagesAbout.length > 0 ? (
            <img
              src={
                imagesAbout.length > 0
                  ? `${LinkLikeBachend}${imagesAbout[0].img_logo}`
                  : ""
              }
              alt="img_about"
            />
          ) : (
            <img src={background_img} alt="background_img" />
          )}

          <input
            type="file"
            id="file_upload"
            name="img_logo"
            accept="image/*"
            onChange={handleImageChangeLogo}
          />
        </div>

        <div className="images">
          {imageAbout ? (
            <img src={imageAbout} alt="selected_img" />
          ) : imagesAbout.length > 0 ? (
            <img
              src={
                imagesAbout.length > 0
                  ? `${LinkLikeBachend}${imagesAbout[0].img_about}`
                  : ""
              }
              alt="img_about"
            />
          ) : (
            <img src={background_img} alt="background_img" />
          )}

          <input
            type="file"
            id="file_upload"
            name="image_About"
            accept="image/*"
            onChange={handleImageChangeAbout}
          />
        </div>

        <textarea
          type="text"
          name="text_title_en"
          required
          placeholder="text title en"
          value={
            textEN ||
            (imagesAbout &&
              imagesAbout.length > 0 &&
              imagesAbout[0].title_about_en)
          }
          onChange={handleTextChangeEN}
        ></textarea>

        <textarea
          style={{ direction: "rtl" }}
          type="text"
          name="text_title_ar"
          required
          placeholder="text title ar"
          value={
            textAR ||
            (imagesAbout &&
              imagesAbout.length > 0 &&
              imagesAbout[0].title_about_ar)
          }
          onChange={handleTextChangeAR}
        ></textarea>

        <input
          type="submit"
          value="Submit"
          style={{ opacity: isDisabledAddAbout ? 0.3 : 1 }}
          disabled={isDisabledAddAbout}
        />
      </form>
    </div>
  );
};

export default AddAbout;
