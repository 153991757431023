import React, { useEffect, useState } from "react";
import "./featuredCollections.css";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const FeaturedCollections = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [imagesSliders, setImagesSliders] = useState([]);

  const fetchImages = async () => {
    const formDataViewSlider = new FormData();

    formDataViewSlider.append("validation", validation);

    try {
      const response = await fetch(`${LinkLikeBachend}read/view_slider.php`, {
        method: "POST",
        body: formDataViewSlider,
      });
      const dataSliders = await response.json();
      setImagesSliders(dataSliders);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const plusSlides = (n) => {
    showSlides(slideIndex + n);
  };

  useEffect(() => {
    showSlides(slideIndex);
    const interval = setInterval(() => {
      plusSlides(1);
    }, 4000);

    return () => clearInterval(interval);
  }, [slideIndex]);

  const showSlides = (n) => {
    const slides = document.querySelectorAll(".mySlides");
    if (!slides.length) return;

    if (n > slides.length) {
      setSlideIndex(1);
    } else if (n < 1) {
      setSlideIndex(slides.length);
    } else {
      setSlideIndex(n);
    }

    slides.forEach((slide, i) => {
      slide.style.display = i === n - 1 ? "block" : "none";
    });
  };

  const { t } = useTranslation();

  return (
    <div className="featured_collections">
      <h2>{t("featured_collections")}</h2>

      <div className="slideshow-container">
        {imagesSliders.length > 0 &&
          imagesSliders.map((img, i) => (
            <div className="mySlides fade" key={i}>
              <div className="numbertext">{`${i + 1}/${
                imagesSliders.length
              }`}</div>
              <img
                src={`${LinkLikeBachend}${img.image_featured_collections}`}
                alt={`Slide ${i}`}
              />
            </div>
          ))}

        <div className="prev_next">
          <a className="prev" onClick={() => plusSlides(-1)}>
            <FaChevronLeft />
          </a>
          <a className="next" onClick={() => plusSlides(1)}>
            <FaChevronRight />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCollections;
