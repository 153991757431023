import React from "react";
import Banner from "./banner/Banner";
import Gallery from "./gallery/Gallery";
import About from "./about/About";
import ContactUs from "./contactUs/ContactUs";
import FeaturedCollections from "./featuredCollections/FeaturedCollections";

const Home = () => {
  return (
    <>
      <Banner />
      <About />
      <Gallery />
      <FeaturedCollections />
      <ContactUs />
    </>
  );
};

export default Home;
