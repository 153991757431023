import React, { useEffect, useState } from "react";
import "./banner.css";
import ScrollVisibility from "../../../components/ScrollVisibility/ScrollVisibility";
import { useAppContext } from "../../../components/context/AppProvider";

const Banner = () => {
  const [imagesMenus, setImagesMenus] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const { direction } = useAppContext();

  const fetchImages = async () => {
    const formDataViewBanner = new FormData();
    formDataViewBanner.append("validation", validation);

    try {
      const response = await fetch(`${LinkLikeBachend}read/view_banner.php`, {
        method: "POST",
        body: formDataViewBanner,
      });
      const dataMenus = await response.json();
      setImagesMenus(dataMenus);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <ScrollVisibility>
      <div className="bgimg-1">
        <img
          src={
            imagesMenus.length > 0
              ? `${LinkLikeBachend}${imagesMenus[0].img_banner}`
              : ""
          }
          alt="background_img"
        />

        <div className="caption">
          <div className="info">
            {direction === "ltr" ? (
              <span className="border">
                {imagesMenus.length > 0
                  ? `${imagesMenus[0].title_banner_en}`
                  : ""}
              </span>
            ) : (
              <span className="border">
                {imagesMenus.length > 0
                  ? `${imagesMenus[0].title_banner_ar}`
                  : ""}
              </span>
            )}
          </div>
        </div>
      </div>
    </ScrollVisibility>
  );
};

export default Banner;
