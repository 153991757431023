import React, { useEffect, useState } from "react";
import "./addBanner.css";
import background_img from "../../../../img/background_img.webp";
import { toast } from "react-toastify";

const AddBanner = () => {
  const [imageBanner, setImageBanner] = useState(null);
  const [image, setImage] = useState(null);
  const [textEN, setTextEN] = useState("");
  const [textAR, setTextAR] = useState("");
  const [imagesBanner, setimagesBanner] = useState([]);
  const [isDisabledAddBanner, setDisabledAddBanner] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchImages = async () => {
    const formDataViewBanner = new FormData();
    formDataViewBanner.append("validation", validation);

    try {
      const response = await fetch(`${LinkLikeBachend}read/view_banner.php`, {
        method: "POST",
        body: formDataViewBanner,
      });
      const dataMenus = await response.json();
      setimagesBanner(dataMenus);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBanner(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleTextChangeEN = (e) => {
    setTextEN(e.target.value);
  };

  const handleTextChangeAR = (e) => {
    setTextAR(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setDisabledAddBanner(true);

    const formDataAddBanner = new FormData();
    formDataAddBanner.append("validation", validation);
    formDataAddBanner.append("image_banner", image);
    formDataAddBanner.append(
      "text_title_en",
      textEN || (imagesBanner.length > 0 && imagesBanner[0].title_banner_en)
    );
    formDataAddBanner.append(
      "text_title_ar",
      textAR || (imagesBanner.length > 0 && imagesBanner[0].title_banner_ar)
    );

    const response = await fetch(`${LinkLikeBachend}update/banner.php`, {
      method: "POST",
      body: formDataAddBanner,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been updated successfully");

        setImage(null);
        setTextEN("");

        fetchImages();

        setDisabledAddBanner(false);
      } else {
        toast.error("Error updating data");
        console.log(resultText);
        setDisabledAddBanner(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error updating data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledAddBanner(false);
    }
  };

  // console.log(imagesBanner);

  return (
    <div className="upload_form">
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="images">
          {imageBanner ? (
            <img src={imageBanner} alt="selected_img" />
          ) : imagesBanner.length > 0 ? (
            <img
              src={`${LinkLikeBachend}${imagesBanner[0].img_banner}`}
              alt="background_img"
            />
          ) : (
            <img src={background_img} alt="background_img" />
          )}

          <input
            type="file"
            id="image_banner"
            name="image_banner"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>

        <input
          type="text"
          name="text_title_en"
          required
          placeholder="text title en"
          value={
            textEN ||
            (imagesBanner.length > 0 && imagesBanner[0].title_banner_en)
          }
          onChange={handleTextChangeEN}
        />

        <input
          style={{ direction: "rtl" }}
          type="text"
          name="text_title_ar"
          required
          placeholder="text title ar"
          value={
            textAR ||
            (imagesBanner.length > 0 && imagesBanner[0].title_banner_ar)
          }
          onChange={handleTextChangeAR}
        />

        <input
          type="submit"
          value="Submit"
          style={{ opacity: isDisabledAddBanner ? 0.3 : 1 }}
          disabled={isDisabledAddBanner}
        />
      </form>
    </div>
  );
};

export default AddBanner;
