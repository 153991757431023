import React, { useEffect, useState } from "react";
import "./contactUs.css";
import { toast } from "react-toastify";

const ContactUs = () => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [ContactUs, setContactUs] = useState(null);
  const [contactUsData, setContactUsData] = useState(null);
  const [isDisabledContactData, setDisabledContactData] = useState(false);
  const [formData, setFormData] = useState({
    validation: validation,
    content_1: "",
    content_2: "",
    content_3: "",
    content_4: "",
    content_5: "",
    instagram: "",
    whatsapp: "",
  });

  const fetchContactUs = async () => {
    const formDataViewContactUs = new FormData();
    formDataViewContactUs.append("validation", validation);

    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_contact_us.php`,
        {
          method: "POST",
          body: formDataViewContactUs,
        }
      );
      const dataMenus = await response.json();
      setContactUs(dataMenus);
    } catch (error) {
      console.error("Error fetching ContactUs:", error);
    }
  };

  const fetchContactUsData = async () => {
    const formDataDataContactUs = new FormData();
    formDataDataContactUs.append("validation", validation);

    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_contact_data.php`,
        {
          method: "POST",
          body: formDataDataContactUs,
        }
      );
      const dataMenus = await response.json();
      setContactUsData(dataMenus);
    } catch (error) {
      console.error("Error fetching ContactUs:", error);
    }
  };

  useEffect(() => {
    fetchContactUs();
    fetchContactUsData();
  }, []);

  const handleChangeContactUs = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      const newData = { ...prevFormData };
      newData[name] = value;
      return newData;
    });
  };

  const handleSubmitContactData = async (e) => {
    e.preventDefault();

    setDisabledContactData(true);

    const formDataContactData = new FormData();
    formDataContactData.append("validation", validation);

    for (const key in formData) {
      formDataContactData.append(key, formData[key]);
    }

    const response = await fetch(`${LinkLikeBachend}update/contact_data.php`, {
      method: "POST",
      body: formDataContactData,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been updated successfully");

        setFormData({
          validation: validation,
          content_1: "",
          content_2: "",
          content_3: "",
          content_4: "",
          content_5: "",
          instagram: "",
          whatsapp: "",
        });

        setDisabledContactData(false);

        fetchContactUsData();
      } else {
        toast.error("Error updating data");
        console.log(resultText);
        setDisabledContactData(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error updating data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledContactData(false);
    }
  };

  return (
    <>
      <form
        method="post"
        className="contact_us_data"
        onSubmit={handleSubmitContactData}
      >
        <div>
          <p>{contactUsData && contactUsData[0].content_1}</p>
          <input
            type="text"
            name="content_1"
            placeholder="Edit"
            value={formData.content_1}
            onChange={handleChangeContactUs}
          />
        </div>

        <div>
          <p>{contactUsData && contactUsData[0].content_2}</p>
          <input
            type="text"
            name="content_2"
            placeholder="Edit"
            value={formData.content_2}
            onChange={handleChangeContactUs}
          />
        </div>

        <div>
          <p>{contactUsData && contactUsData[0].content_3}</p>
          <input
            type="text"
            placeholder="Edit"
            name="content_3"
            value={formData.content_3}
            onChange={handleChangeContactUs}
          />
        </div>

        <div>
          <p>phone number</p>
          <p>{contactUsData && contactUsData[0].content_4}</p>
          <input
            type="text"
            placeholder="Edit"
            name="content_4"
            value={formData.content_4}
            onChange={handleChangeContactUs}
          />
        </div>

        <div>
          <p>email</p>
          <p>{contactUsData && contactUsData[0].content_5}</p>
          <input
            type="email"
            placeholder="Edit"
            name="content_5"
            value={formData.content_5}
            onChange={handleChangeContactUs}
          />
        </div>

        <div>
          <p>instagram</p>
          <p>{contactUsData && contactUsData[0].instagram}</p>
          <input
            type="text"
            placeholder="user name"
            name="instagram"
            value={formData.instagram}
            onChange={handleChangeContactUs}
          />
        </div>

        <div>
          <p>whatsapp</p>
          <p>{contactUsData && contactUsData[0].whatsapp}</p>
          <input
            type="number"
            placeholder="96659987445"
            name="whatsapp"
            value={formData.whatsapp}
            onChange={handleChangeContactUs}
          />
        </div>

        <input
          type="submit"
          value="Submit"
          style={{ opacity: isDisabledContactData ? 0.3 : 1 }}
          disabled={isDisabledContactData}
        />
      </form>

      <div className="view_ContactUs">
        {ContactUs && ContactUs.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>company name</th>
                <th>country</th>
                <th>Mailing approval status</th>
                <th>phone number approval status</th>
              </tr>
            </thead>
            <tbody>
              {ContactUs.map((contact, index) => (
                <tr key={index}>
                  <td>{contact.full_name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.company_name}</td>
                  <td>{contact.country}</td>
                  <td>{contact.active_email}</td>
                  <td>{contact.active_phone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No contact data available.</p>
        )}
      </div>
    </>
  );
};

export default ContactUs;
