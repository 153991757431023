import React, { useEffect } from "react";
import Header from "./components/header/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import NotFound from "./pages/notFound/NotFound";
import ContactUs from "./pages/home/contactUs/ContactUs";
import About from "./pages/home/about/About";
import Gallery from "./pages/home/gallery/Gallery";
import Authentication from "./admin/authentication/Authentication";
import { useAppContext } from "./components/context/AppProvider";
import HeaderAdmin from "./admin/dashboard/header/HeaderAdmin";
import AddBanner from "./admin/dashboard/page/addBanner/AddBanner";
import AddAbout from "./admin/dashboard/page/addAbout/AddAbout";
import Contact_Us from "./admin/dashboard/page/contactUs/ContactUs";
import Categories from "./admin/dashboard/page/categories/Categories";
import AddPost from "./admin/dashboard/page/addPost/AddPost";
import AllPost from "./admin/dashboard/page/addPost/allPost/AllPost";
import Post from "./pages/home/post/Post";
import AddFeaturedCollections from "./admin/dashboard/page/addFeaturedCollections/AddFeaturedCollections";

const App = () => {
  const location = useLocation();
  const { direction, setDirection } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div style={{ direction: direction }}>
      <Routes>
        <Route
          path="/"
          element={<Header setDirection={setDirection} direction={direction} />}
        >
          <Route path="/" element={<Home />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="about" element={<About />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path=":post" element={<Post />} />
          <Route path=":post/:categoriesId" element={<Post />} />
        </Route>

        <Route path="/admin" element={<Authentication />} />

        <Route path="/dashboard" element={<HeaderAdmin />}>
          <Route path="/dashboard" element={<AddBanner />} />
          <Route path="add-about" element={<AddAbout />} />
          <Route path="contact" element={<Contact_Us />} />
          <Route path="categories" element={<Categories />} />
          <Route
            path="featured-collections"
            element={<AddFeaturedCollections />}
          />
          <Route path="add-post" element={<AddPost />} />
          <Route path="all-post" element={<AllPost />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
